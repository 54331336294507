<template>
  <div>
    <Toolbar class="p-mb-4">
      <template #left>
        <Button
          icon="pi pi-print"
          @click="printList()"
          :disabled="add"
          label="طباعه"
        />
      </template>
      <template #right>
        <Button
          icon="pi pi-times"
          class="p-button-danger"
          @click="$router.push('/admin/carnics')"
        />
      </template>
    </Toolbar>
    <div class="text-center" v-if="add">
      <ProgressSpinner />
    </div>
    <DataTable
      v-else
      :value="list"
      :filters="filters"
      :loading="loading"
      :expandedRows.sync="expandedRows"
    >
      <template #empty>
        لا يوجد بيانات
      </template>
      <template #loading>
        يتم تحميل البيانات. يرجي الانتظار...
      </template>
      <Column
        field="image"
        header="الشعار"
        bodyStyle="text-align: center; overflow: visible"
      >
        <template #body="slotProps">
          <img :src="$baseUploadURL + slotProps.data.image" class="dt-image" />
        </template>
      </Column>
      <Column field="userName" header="الاسم" :sortable="true">
        <template #filter>
          <input
            class="form-control"
            id="userName"
            v-model="filters['userName']"
          />
        </template>
      </Column>

      <Column :expander="true" headerStyle="width: 3rem" />

      <template #expansion="slotProps">
        <div class="orders-subtable">
          <DataTable
            :value="slotProps.data.list"
            :selection.sync="selectedItems"
            class="p-datatable-customers"
          >
            <Column
              selectionMode="multiple"
              field="id"
              headerStyle="width: 3em"
            />
            <Column
              field="image"
              header="الصوره الشخصيه"
              bodyStyle="text-align: center; overflow: visible"
            >
              <template #body="slotProps">
                <img
                  :src="$baseUploadURL + slotProps.data.image"
                  class="dt-image"
                />
              </template>
            </Column>
            <Column field="name" header="الاسم" :sortable="true" />
            <Column
              field="nameEn"
              header="الاسم بالانجليزيه"
              :sortable="true"
            />
            <Column field="schoolName" header="اسم المدرسه" :sortable="true" />
            <Column field="nationalID" header="الرقم القومي" :sortable="true" />
          </DataTable>
        </div>
      </template>
    </DataTable>
  </div>
</template>

<script>
export default {
  data() {
    return {
      id: null,
      leaguesId: null,
      list: [],
      selectedItems: [],
      expandedRows: [],

      filters: {},
      loading: true,
      body: {},
      add: false,
    };
  },
  methods: {
    printList() {
      this.add = true;
      const list = [];
      for (const item of this.selectedItems) {
        list.push({
          id: item.id,
          name: item.name,
          nationalID: item.nationalID,
          schoolName: item.schoolName,
          academieName: item.academieId.userName,
          image: item.image,
          leaguesId: item.teams.map((itm) => itm.yearsAcademieId.yearId.name),
          dateOfBirth: item.dateOfBirth,
          teams: item.teams.map((itm) => itm.name),
        });
      }
      this.$http
        .post(`carnics/print`, {
          list,
          body: this.body,
          baseUploadURL: this.$baseUploadURL,
        })
        .then(
          (res) => {
            this.add = false;
            window.open(this.$basePdfURL + res.data, '_blank');
            this.$toast.add({
              severity: 'success',
              summary: 'تم بنجاح',
              detail: 'تم الطباعه بنجاح',
              life: 3000,
            });
          },
          (err) => {
            this.add = false;

            this.$toast.add({
              severity: 'error',
              summary: 'هناك خطأ',
              detail: err.response.data.message,
              life: 3000,
            });
          },
        );
    },
    getData() {
      this.$http
        .post(`players/search`, {
          relations: ['academieId', 'leaguesId', 'teams'],
          where: {
            leaguesId: this.leaguesId,
          },
          select: [
            'id',
            'name',
            'nameEn',
            'image',
            'schoolName',
            'nationalID',
            'academieId',
            'leaguesId',
            'dateOfBirth',
          ],
        })
        .then(
          (response) => {
            this.loading = false;
            const playersList = response.data;
            const academieList = playersList.map((el) => el.academieId);
            let uniquacademieListe = [
              ...new Map(
                academieList.map((item) => [item['id'], item]),
              ).values(),
            ];
            for (const item of uniquacademieListe) {
              item.list = playersList.filter(
                (el) => el.academieId.id == item.id,
              );
            }
            this.list = uniquacademieListe;
          },
          (err) => {
            this.loading = false;
            this.$toast.add({
              severity: 'error',
              summary: 'هناك خطأ',
              detail: err.response.data.message,
              life: 3000,
            });
          },
        );
    },
  },
  created() {
    this.id = this.$route.params.id;
    this.$http.get(`carnics/${this.id}`).then(
      (res) => {
        this.body = res.data;
        this.body.selected = JSON.parse(res.data.selected);
      },
      (err) => {
        this.$toast.add({
          severity: 'error',
          summary: 'هناك خطأ',
          detail: err.response.data.message,
          life: 3000,
        });
      },
    );

    this.leaguesId = this.$route.params.leaguesId;
    this.getData();
  },
};
</script>

<style></style>
